<template>
    <div class="content-discovery-menu-channel m-t-2 w-75">
        <FormInfoBox
            button-text="Edit Search"
            :message="logicJumpText"
            :errors="errors.logicJump"
            @click="showContentSelector = true"
        >
            <Input
                id="content-discovery-channel"
                type="text"
                placeholder="Search for a channel"
                label="Specify a channel"
                name="content-discovery-channel"
                default-icon="search"
                :default-icon-size="16"
                :has-margin-bottom="false"
                @focus="showContentSelector = true"
            />
        </FormInfoBox>
        <ContentSelector
            v-if="showContentSelector"
            v-model="logicJump"
            validation-content-type="channel"
            :show-content-id="true"
            @close="showContentSelector = false"
        />
    </div>
</template>
<script>
import { Input, FormInfoBox } from '@/components/forms';
import ContentSelector from '@/components/ContentSelector/ContentSelector';
import { LogicJump } from '@/store/models';
import ContentDiscoveryMixin from '@/mixins/contentDiscoveryMixin';
export default {
    name: 'ContentDiscoveryChannel',
    components: {
        Input,
        FormInfoBox,
        ContentSelector,
    },
    mixins: [ContentDiscoveryMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            showContentSelector: false,
        };
    },
    computed: {
        logicJump: {
            get() {
                return this.data?.logicJump?.items || {};
            },
            set(content) {
                this.onInput('logicJump', new LogicJump(content));
            },
        },
    },
};
</script>
